import { Box, Container, SxProps, Theme, Typography } from '@mui/material'
import type { ProductGridFragment } from './ProductGrid.gql'
import { trpc } from '../../../../lib/trpc/utils/trpc'
import { Loading } from '../../../Loading'
import { PageContentComponentProps } from '../../../../types'
import {
  ProductListItems,
  ProductListItemsProps,
  sxLargeItem,
  sxSmallItem,
} from '../../../ProductListItems'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { AppRouter } from '../../../../lib/trpc/server/routers/_app'
import { inferRouterInputs } from '@trpc/server'
import IconLinkCaretRight from '../HeroGallery/link-caret-right.svg'
import { Image } from '@graphcommerce/image'
import { Trans } from '@lingui/react'
import Link from 'next/link'

export type ProductGridProps = PageContentComponentProps & ProductGridFragment

const name = 'CraftProductGrid' as const
const parts = ['root', 'title'] as const
const { classes } = extendableComponent(name, parts)

type ProductGridStyle = 'smallSquare' | 'largeSquare'

export const ProductGrid = (props: ProductGridProps) => {
  const {
    id: blockId,
    skus,
    categoryId,
    locale,
    productGridTitle,
    productGridDescription,
    productGridStyle,
    enableViewAll,
    customShowProductsLimit,
    viewAllUrlOverride,
  } = props

  const style = productGridStyle as ProductGridStyle

  const blockSkus = (skus || [])
    .filter((skuRow) => typeof skuRow?.sku === 'string' && !!skuRow?.sku)
    .map((skuRow) => skuRow?.sku) as string[]

  const queryArgs: inferRouterInputs<AppRouter>['query']['products'] = {
    locale,
  }

  if (blockSkus.length > 0) {
    queryArgs.skus = blockSkus
  } else if (categoryId) {
    queryArgs.categoryId = +categoryId

    switch (style) {
      case 'smallSquare':
        queryArgs.categoryMaxProducts = customShowProductsLimit ?? 12
        break
      case 'largeSquare':
        queryArgs.categoryMaxProducts = customShowProductsLimit ?? 8
        break
    }
  }

  const { data: products, isLoading } = trpc.query.products.useQuery(queryArgs, {
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: false,
  })

  const styleSizeSxMap = new Map<ProductGridStyle, SxProps<Theme>>([
    ['smallSquare', sxSmallItem],
    ['largeSquare', sxLargeItem],
  ])

  const styleSizeMap = new Map<ProductGridStyle, ProductListItemsProps['size']>([
    ['smallSquare', 'small'],
    ['largeSquare', 'normal'],
  ])

  return (
    <Container className={classes.root}>
      {productGridTitle && (
        <Box
          className={classes.title}
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: theme.spacings.xs,
            marginBottom: theme.spacings.xxs,

            '& a': {
              whiteSpace: 'nowrap',
              textTransform: 'uppercase',
              lineHeight: 1,
              textDecoration: 'none',
              color: 'inherit',
            },
          })}
        >
          <Box sx={{ maxWidth: '700px' }}>
            <Typography component='div' variant='h4'>
              {productGridTitle}
            </Typography>
            {productGridDescription && (
              <Typography variant='body1'>{productGridDescription}</Typography>
            )}
          </Box>
          {!!products?.viewAllUrl && !!enableViewAll && (
            <Link href={viewAllUrlOverride || products?.viewAllUrl}>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacings.xxxs,
                  marginBottom: theme.spacings.xxs,
                })}
              >
                <Typography component='span' variant='body1' sx={{}}>
                  <Trans id='View All' />
                </Typography>
                <Image
                  src={IconLinkCaretRight}
                  unoptimized
                  sx={{
                    filter: 'invert(1)',
                    height: '1em',
                  }}
                />
              </Box>
            </Link>
          )}
        </Box>
      )}
      {isLoading && <Loading />}
      {!isLoading && !!products && (
        <ProductListItems
          title='Product Block'
          items={products.items}
          loadingEager={1}
          size={styleSizeMap.get(style)}
          sx={styleSizeSxMap.get(style)}
        />
      )}
    </Container>
  )
}
